<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Relationship Managers</a></li>
    </ol>
    <h1 class="page-header">Relationship Managers</h1>
    <panel noButton="true" title="Relationship Managers" variant="panel-default">
      <div class="row mb-3">
        <div class="input-group col-md-4">
          <input
              v-model="search"
              class="form-control py-2 border-right-0 border"
              placeholder="Search ..."
              type="search"
          >
          <span class="input-group-append">
          <button
              class="btn btn-outline-secondary border-left-0 border"
              type="button"
          >
            <i class="fa fa-search"/>
          </button>
        </span>
        </div>
        <div class="col-md-8 text-right">
          <button
              v-if="$can.view($store.getters.getAuthUser, 'create-relationship-managers')"
              class="btn btn-sm btn-info mr-2"
              type="button"
              @click.prevent="showImportModal = true"
          >
            <i
                class="fas fa-file-excel"
            /> Import Excel
          </button>
          <router-link v-if="$can.view($store.getters.getAuthUser, 'create-relationship-managers')"
                       :to="{name:'rms.create'}"
                       class="btn btn-sm btn-primary"
          >
            <i class="fa fa-user-plus"></i> Assign RM
          </router-link>
        </div>
      </div>
      <loading v-if="loading"/>
      <table v-else class="table table-striped table-responsive-sm">
        <thead>
        <tr>
          <th>#</th>
          <th>Code</th>
          <th>Name</th>
          <th>Email</th>
          <th>Branch</th>
          <th v-if="$can.view($store.getters.getAuthUser, 'create-relationship-managers')">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in rms" :key="i">
          <td>{{ pagination.from + i }}</td>
          <td>{{ item.code }}</td>
          <td>{{ fullname(item) }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.name }}</td>
          <td v-if="$can.view($store.getters.getAuthUser, 'create-relationship-managers')">
            <router-link :to="{name:'rms.create',params:{id: item.id}}" class="btn btn-sm btn-info" title="edit" type="button">
              <i class="fa fa-edit"/>
            </router-link>
            <button class="btn btn-sm ml-1 btn-danger" title="Delete" type="button" @click.prevent="deleteRM(item)">
              <i class="fa fa-trash"/>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <pagination
          v-if="pagination.last_page > 1"
          :offset="5"
          :pagination="pagination"
          @paginate="fetch"
      />
    </panel>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5 v-if="rm">Edit RM</h5>
        <h5 v-else>Create RM</h5>
      </template>
      <form
          @submit.prevent="save()"
      >
        <div v-if="error" v-html="$error.handle(error)"/>
        <div class="row">
          <div class="form-group col-md-8">
            <label>Name</label>
            <input
                v-model="name"
                class="form-control"
                required
                type="text"
            >
          </div>
        </div>
        <div class="form-group">
          <button
              class="btn btn-primary"
              type="submit"
          >
            <span v-if="rm">Update</span>
            <span v-else>Create</span>
          </button>
        </div>
      </form>
    </modal>
    <modal
        :show-modal="showImportModal"
        @close="showImportModal = false"
    >
      <template v-slot:header>
        <h5>Import Relationship Managers</h5>
      </template>
      <div v-html="$error.handle(error)"/>
      <form
          @submit.prevent="importFile"
      >
        <div class="form-group">
          <label>Choose Excel File</label>
          <input
              ref="import_file"
              :class="{ 'invalid-feedback' : error.message }"
              accept=".xlsx"
              class="form-control"
              name="file_import"
              required
              type="file"
              @change="onFileChange"
          >
        </div>

        <div class="form-group">
          <button
              v-if="upload"
              class="btn btn-primary"
              type="submit"
          >
            <i class="fa fa-upload"></i> Upload
          </button>
          <button
              v-else
              class="btn btn-warning"
          >
            Loading <i class="fa fa-spinner fa-spin"/>
          </button>
          <button
              class="btn btn-success"
              style="margin-left: 5px;"
              type="button"
              @click="downloadFile"
          >
            <i class="fa fa-download"></i> Download Sample
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import helper from "@/mixins/helper";

export default {
  mixins: [helper],
  data() {
    return {
      search: '',
      upload: true,
      loading: true,
      rms: [],
      showModal: false,
      showImportModal: false,
      rm: null,
      name: '',
      error: {},
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  computed: {
    ...mapGetters({rm_users: 'getRelationshipManagers'})
  },
  watch: {
    search(o, n) {
      if (!n && !o) {
        return
      }
      this.fetchEntriesDebounced()
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onFileChange(e) {
      this.import_file = e.target.files[0];
    },
    importFile() {
      let formData = new FormData();
      formData.append('import_file', this.import_file);
      this.error = "";
      this.upload = false;
      this.$axios.post('/api/v1/relationship_managers/import', formData, {
        headers: {'content-type': 'multipart/form-data'}
      }).then(() => {
        this.upload = true;
        this.$toastr.s('Relationship manager list imported successfully');
        this.$router.go();
      }).catch(error => {
        this.upload = true;
        this.$toastr.e('An error occured!');
        this.error = error.response;
      });
    },
    downloadFile() {
      this.$axios({
        url: '/api/v1/relationship_managers/download',
        method: 'POST',
        responseType: 'arraybuffer',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'rm_export.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    },
    fetch() {
      this.loading = true;
      this.$axios.get('/api/v1/relationship_managers', {
        params: {
          page: this.pagination.current_page,
          search: this.search
        }
      }).then(response => {
        this.loading = false;
        if (!Object.prototype.hasOwnProperty.call(response.data, "data")) return;
        this.rms = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    },
    save() {
      if (this.rm) {
        this.edit();
        return;
      }
      this.$axios.post('/api/v1/relationship_managers', {'name': this.name}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.rm = null;
        this.$toastr.s('Relationship Manager Branch created');
      }).catch(error => {
        this.setError(error);
      });
    },
    edit() {
      this.$axios.put(`/api/v1/relationship_managers/${this.rm.id}`, {name: this.name}).then(() => {
        this.showModal = false;
        this.fetch();
        this.name = '';
        this.rm = null;
        this.$toastr.s('Relationship Manager branch updated');
      }).catch(error => {
        this.setError(error);
      });
    },
    deleteRM(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the Relationship Manager " + item.firstname + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete(`/api/v1/relationship_managers/${item.id}`).then(() => {
            this.$toastr.s('Relationship Manager deleted');
            this.fetch();
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },
    setError(err) {
      this.error = err.response;
      setTimeout(() => {
        this.error = null;
      }, 6000);
    },
    rmModal(rm) {
      if (rm != null) {
        this.rm = rm;
        this.name = rm.name;
      } else {
        this.rm = null;
      }
      this.showModal = true;
    }
  }
}
</script>